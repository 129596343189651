import React from 'react'
import { Table } from 'semantic-ui-react'

const PedicureSpaChairSpecs = () => {
  return (
    <div className="py-4 animated fadeInUp slow">
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <h1>SPECIFICATIONS</h1>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Shipping Specs</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>42” Length x 56” Width x 60”Height (1 Pallet)</p>
              <p>107cm Length x142cm Width x 152cm Height</p>
              <p>330 lb. (pounds)</p>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Tub Capacity</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>4.5 US Gallons (17 Liters)</p>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Switch</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>On/Off Air Activated</p>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Jet System</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                <strong>100% Disposable CONTÉGO Jet-Liner</strong>
              </p>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Power Source</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>120 VAC, 60Hz, 3 AMP</p>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Plumbing</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                <strong>Hot/Cold Water:</strong> 3/8” FIP Fittings
              </p>
              <br />
              <p>
                <strong>FIP Fittings Drain:</strong> 1” PVC Slip Male Fitting
              </p>
              <br />
              <p>
                <strong>Optional Drain Pump:</strong> 3/4” PVC Slip Female Fitting (Additional
                Purchase)
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>
              <p>
                <strong>Air Vent System</strong>
              </p>
            </Table.Cell>
            <Table.Cell>
              <p>
                <strong>2” Diameter Air Hose (ADDITIONAL PURCHASE)</strong>
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default PedicureSpaChairSpecs
