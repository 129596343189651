import React from 'react'
import Vimeo from 'react-player/vimeo'

export default ({ url, autoPlay }) => (
  <div className="player-wrapper">
    <Vimeo
      className="react-player"
      url={url}
      playing={autoPlay}
      controls
      width="100%"
      height="100%"
    />
  </div>
)
