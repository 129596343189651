import React, { useEffect, useState } from 'react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Card } from 'semantic-ui-react'

import ResEmbeddedVimeo from './ResEmbeddedVimeo'

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay])

const features = [
  {
    name: 'jet-liner',
    title: '100% Disposable JET',
    videoUrl: 'https://player.vimeo.com/video/434470106',
    des: `To prevent cross-contamination from sharing the magnetic jet.`,
    shortText: '100% Disposable Jet'
  },
  {
    name: 'infinity-overflow',
    title: 'Infinity Overflow',
    videoUrl: 'https://player.vimeo.com/video/434470166',
    des: `To prevent water from overflowing out of the bowl and in your salon.`,
    shortText: 'Infinity Overflow'
  },
  {
    name: 'easy-drain',
    title: 'EasyDrain',
    videoUrl: 'https://player.vimeo.com/video/434470139',
    des: `To protect the nail technician’s hands from being exposed to bacteria and fungus inside water from each service.`,
    shortText: 'EasyDrain'
  },
  // {
  //   name: 'armrest',
  //   title: 'Durable Armrest & Tray Table',
  //   videoUrl: 'https://player.vimeo.com/video/434470080',
  //   des: `Durable and acetone resistant materials to help ensure our products last.`,
  //   shortText: 'Armrest'
  // },
  {
    name: 'footrest',
    title: 'Adjustable Footrests',
    videoUrl: 'https://player.vimeo.com/video/434470066',
    des: `Adjustable footrests free technicians from holding customers’ feet during service and give the flexibility to serve different leg lengths.`,
    shortText: 'Footrest'
  },
  {
    name: 'showerhead',
    title: 'Dual Function Showerhead',
    videoUrl: 'https://player.vimeo.com/video/434470124',
    des: 'Dual function showerhead for both rain and stream mode',
    shortText: 'Showerhead'
  },
  {
    name: 'basin',
    title: '7-Colored LED Basin Light',
    videoUrl: 'https://player.vimeo.com/video/434470037',
    des: 'With 7 different colors, our LED will light up the basin with an energetic look',
    shortText: 'Basin'
  }
]

const ChairFeatures = () => {
  const [slidesPerView, setSlidesPerView] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.screen.width

      if (screenWidth >= 500) {
        setSlidesPerView(2.2)
      } else {
        setSlidesPerView(1.2)
      }
    }

    if (slidesPerView) {
      window.addEventListener('resize', handleResize)
    } else {
      handleResize()
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [slidesPerView])

  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="py-4 w-100 text-center">ADVANCED & PATENTED TECHNOLOGIES</h1>
        </div>
      </div>
      {slidesPerView && (
        <div className="row">
          <div className="col">
            <Swiper
              spaceBetween={15}
              slidesPerView={slidesPerView}
              centeredSlides
              centeredSlidesBounds
              pagination={{ type: 'bullets', clickable: true }}
              mousewheel={{ forceToAxis: true }}
              className="px-2"
            >
              {features.map(feature => {
                return (
                  <SwiperSlide key={feature.name}>
                    <Card raised className="w-100 mx-2">
                      <ResEmbeddedVimeo url={feature.videoUrl} width="100%" height="100%" />
                      <h3 className="text-secondary px-4 mb-0">{feature.title}</h3>
                      <p className="px-4 pb-4">{feature.des}</p>
                    </Card>
                  </SwiperSlide>
                )
              })}
              <br /> <br />
              <br />
            </Swiper>
          </div>
        </div>
      )}
    </>
  )
}

export default ChairFeatures
